<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box" style="min-height:720px">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <AppLink v-for="(item, key) of groupData" :key="key" :title="key" :list="item" parentKey='market' parentModule="market"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Index',

  data() {
    return {
      loading: false,
      groupData: {}
    }
  },
  computed: {
    ...mapGetters(['marketRouter'])
  },
  created() {
    this.getList()
  },

  methods: {
    async getList() {
      this.loading = true
      let list = await this.$store.dispatch('marketListAction')
      // console.log(this.marketRouter,'111111')
      this.groupData = list
      this.loading = false
    }
  },
  watch: {
    marketRouter(newValue, oldValue) {
      console.log(newValue)
    }
  },
}
</script>
